@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
.feature {
  margin-bottom: 90px;
  height: 380px;
}
@media screen and (max-width: 1367px) {
  .feature {
    margin-bottom: 70px;
    height: 300px;
  }
}
@media screen and (max-width: 1000px) {
  .feature {
    margin-bottom: 30px;
    height: auto;
  }
}
.feature .feature-description-container,
.feature .feature-image {
  height: 380px;
  width: 600px;
  float: left;
  background-color: rgba(0,0,0,0.5);
}
@media screen and (max-width: 1367px) {
  .feature .feature-description-container,
  .feature .feature-image {
    height: 300px;
    width: 480px;
  }
}
@media screen and (max-width: 1000px) {
  .feature .feature-description-container,
  .feature .feature-image {
    height: auto;
    width: auto;
    float: none;
  }
}
@media screen and (max-width: 1000px) {
  .feature .feature-image {
    display: none;
  }
}
.feature .feature-description-container div {
  padding: 25px 33px;
}
.feature .feature-description-container div h3 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 32px;
  margin: 0 0 10px 0;
  padding: 0;
}
@media screen and (max-width: 1367px) {
  .feature .feature-description-container div h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1000px) {
  .feature .feature-description-container div h3 {
    font-size: 26px;
  }
}
.feature .feature-description-container div p {
  color: #b3b3e5;
  line-height: 36px;
}
@media screen and (max-width: 1367px) {
  .feature .feature-description-container div p {
    line-height: 155%;
    font-size: 19px;
  }
}
@media screen and (max-width: 1000px) {
  .feature .feature-description-container div p {
    line-height: 36px;
  }
}
.feature-img-left .feature-description-container {
  border-left: 1px solid #00f;
}
@media screen and (max-width: 1000px) {
  .feature-img-left .feature-description-container {
    border-left: none;
  }
}
.feature-img-right .feature-description-container {
  border-right: 1px solid #00f;
}
@media screen and (max-width: 1000px) {
  .feature-img-right .feature-description-container {
    border-right: none;
  }
}
