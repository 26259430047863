@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
.special-thanks {
  margin: 30px auto 110px auto;
}
.special-thanks h3 {
  margin: 10px;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  text-shadow: 0 0 25px rgba(0,0,255,0.7);
  text-align: center;
}
.special-thanks span {
  font-size: 12px;
  display: block;
  text-align: center;
  margin: -10px 0 10px;
}
.special-thanks .special-thanks-description {
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  display: inline-block;
}
