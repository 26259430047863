@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
#main-title.media-title {
  margin: 0 0 140px 0;
}
.media {
  margin: 60px auto;
}
.media .fotorama__wrap {
  margin: 0px auto;
}
