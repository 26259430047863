@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
#main-nav.affix-top ul li {
  height: 42px;
  margin: 25px 0;
  padding: 0 70px;
}
@media screen and (max-width: 1367px) {
  #main-nav.affix-top ul li {
    height: 36px;
    margin: 23px 0;
    padding: 0 60px;
  }
}
#main-nav.affix {
  width: 100%;
  top: -1px;
  position: fixed !important;
}
#main-nav.affix ul {
  height: 55px;
}
@media screen and (max-width: 1367px) {
  #main-nav.affix ul {
    height: 45px;
  }
}
#main-nav.affix ul li {
  margin: 10px 0;
  padding: 0 70px;
  height: 33px;
  border-right: 1px solid transparent;
}
@media screen and (max-width: 1367px) {
  #main-nav.affix ul li {
    margin: 6px 0;
    padding: 0 40px;
    height: 36px;
  }
}
#main-nav.affix ul li:first-child {
  border-left: 1px solid transparent;
}
#main-nav.affix ul li a {
  font-size: 24px;
}
@media screen and (max-width: 1367px) {
  #main-nav.affix ul li a {
    font-size: 19px;
  }
}
#main-nav.affix ul li.selected a:after {
  bottom: -3px;
}
#hamburger {
  display: none;
  position: fixed;
  top: 15px;
  right: 15px;
  width: 75px;
  height: 75px;
  background: rgba(0,0,0,0.65);
  border: 1px solid #00f;
  z-index: 29732178;
}
#hamburger svg {
  z-index: 39732178;
}
#hamburger svg rect.a {
  stroke: #00f;
  transition: 0.45s ease all;
}
#hamburger.hamburger-active svg rect.a.movable {
  transform: rotate(-45deg) translate(-35px, 5px);
}
#hamburger.hamburger-active svg rect.a.movable:first-child {
  transform: rotate(45deg) translate(14px, -24px);
}
#hamburger.hamburger-active svg rect.a.removable {
  opacity: 0;
}
@media screen and (max-width: 1000px) {
  #hamburger {
    display: inline-block;
  }
}
#main-nav {
  position: absolute;
  top: 285px;
  border-top: 1px solid #00f;
  border-bottom: 1px solid #00f;
  background-color: #000;
  width: 100%;
  z-index: 2000;
}
@media screen and (max-width: 1000px) {
  #main-nav {
    position: fixed;
    top: 0;
    transform: translateY(-105%);
    transition: 0.45s ease all;
  }
  #main-nav.mobilenav-active {
    transform: translateX(0%);
  }
  #main-nav.affix {
    position: static;
    top: 0;
  }
  #main-nav.affix ul {
    height: 100%;
  }
  #main-nav.affix ul li {
    height: 36px;
    margin: 23px 0;
    padding: 0 60px;
    border-right: 1px solid transparent;
  }
  #main-nav.affix ul li a {
    font-size: 26px;
  }
}
#main-nav ul {
  list-style: none;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  transition: 0.3s all;
  height: 90px;
}
@media screen and (max-width: 1367px) {
  #main-nav ul {
    height: 80px;
  }
}
@media screen and (max-width: 1000px) {
  #main-nav ul {
    height: 100%;
  }
}
#main-nav ul li {
  display: inline-block;
  border-right: 1px solid #00f;
  transition: 0.3s all;
/*poniżej powtórzenie celowe, nie ruszać*/
  height: 42px;
  margin: 25px 0;
  padding: 0 70px;
  position: relative;
}
@media screen and (max-width: 1367px) {
  #main-nav ul li {
    height: 39px;
    margin: 30px 0;
    padding: 0 60px;
  }
}
@media screen and (max-width: 1000px) {
  #main-nav ul li {
    display: block;
  }
}
#main-nav ul li.selected a:after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #00f;
  width: 100%;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  box-shadow: 0 0 5px #00f, 0 0 20px #00f;
}
@media screen and (max-width: 1367px) {
  #main-nav ul li.selected a:after {
    font-size: 26px;
  }
}
#main-nav ul li:first-child {
  border-left: 1px solid #00f;
}
#main-nav ul li a {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
  text-shadow: 0 0 25px #00f;
  transition: 0.3s all;
  position: relative;
}
@media screen and (max-width: 1367px) {
  #main-nav ul li a {
    font-size: 26px;
  }
}
#main-nav ul li a:hover {
  text-shadow: 0 0 10px #00f, 0 0 50px #00f;
  color: #fff;
}
#main-nav ul li a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10%;
  left: 50%;
  width: 0%;
  height: 1px;
  transform: translate(-50%, 0);
  background-color: #bbb;
  box-shadow: 0 0 2px 0px #fff;
  transition: 0.3s all;
  z-index: 323323232;
}
#main-nav ul li a:hover:before {
  width: 100%;
  transform: translate(-50%, 0);
}
