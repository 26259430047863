@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
html {
  height: 100%;
}
body {
  overflow-x: hidden;
  height: 100%;
  background-color: #0f1c2f !important;
}
body a:hover,
body a:focus {
  text-decoration: none;
  outline: none;
}
#page-wrapper {
  min-height: 100%;
  position: relative;
}
.background {
  position: fixed;
  width: 100%;
  height: 600%;
  top: 0;
}
.background.bg-image {
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -4;
}
h1,
h2 {
  text-transform: uppercase;
  font-weight: normal;
}
#main-container {
  margin: 0 auto;
  padding-bottom: 200px;
}
@media screen and (max-width: 1000px) {
  #main-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
#main-title {
  text-align: center;
  padding: 55px 0 60px 0;
  margin: 0 0 100px 0;
  height: 300px;
  background-image: url("../img/logo-top.png");
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1000px) {
  #main-title {
    margin: 0;
    background-size: 450px;
  }
}
.main-content {
  width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1367px) {
  .main-content {
    width: 960px;
  }
}
@media screen and (max-width: 1000px) {
  .main-content {
    width: auto;
  }
}
.main-content p.main-heading {
  text-align: center;
  font-size: 26px;
  padding: 40px 0 30px 0;
  line-height: 175%;
  margin: 0 125px;
}
@media screen and (max-width: 1367px) {
  .main-content p.main-heading {
    font-size: 23px;
    margin: 0 100px;
  }
}
@media screen and (max-width: 1000px) {
  .main-content p.main-heading {
    margin: 0;
    padding: 0;
  }
}
.anchor-offset {
  height: 10px;
  position: absolute;
  top: -70px;
}
.main-buttons-container {
  text-align: center;
  margin-bottom: 40px;
}
.main-buttons-container .main-button {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  margin-right: 20px;
}
.main-buttons-container .play-conditions {
  font-size: 12px;
  text-transform: uppercase;
}
.main-buttons-container .main-button {
  margin-bottom: 20px;
}
.main-buttons-container .main-button a {
  padding: 10px 0;
  width: 245px;
  box-shadow: 0 0 6px 1px rgba(0,0,0,0.4);
  display: inline-block;
  margin: 0 auto 10px auto;
  max-width: 360px;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 64px;
  transition: 0.25s all;
  position: relative;
}
.main-buttons-container .main-button a.play-button {
  background-color: rgba(90,150,75,0.7);
  border: 1px solid rgba(255,255,255,0.3);
}
.main-buttons-container .main-button a.play-button:hover {
  background-color: #5a964b;
}
.main-buttons-container .main-button a.wiki-button {
  background-color: rgba(30,30,90,0.7);
  border: 1px solid rgba(0,0,255,0.4);
}
.main-buttons-container .main-button a.wiki-button:before {
  border: 1px solid rgba(0,0,255,0.6);
}
.main-buttons-container .main-button a.wiki-button:hover {
  background-color: #282878;
}
.main-buttons-container .main-button a:hover {
  color: #fff;
  box-shadow: 0 0 6px 1px rgba(0,0,0,0.7);
}
.main-buttons-container .main-button a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: 0.25s all;
  border: 1px solid #fff;
}
@media screen and (max-width: 767px) {
  .main-content p.main-heading {
    font-size: 20px;
  }
  #main-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  #main-title {
    background-size: 400px;
  }
  .main-content .feature .feature-description-container div {
    padding: 20px 30px;
  }
  .main-content .feature .feature-description-container div h3 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 24px;
    margin: 0 0 10px 0;
    padding: 0;
  }
  .main-content .feature .feature-description-container div p {
    color: #b3b3e5;
    line-height: 29px;
    font-size: 18px;
    margin: 0;
  }
  .main-content .feature .feature-description-container div p .faq {
    margin: 20px 0;
    font-size: 25px;
    font-weight: 500;
    padding: 30px 30px 30px 80px;
    background-color: rgba(0,0,0,0.5);
    color: #b3b3e5;
  }
  .main-content .feature .feature-description-container div p .faq .question {
    text-shadow: 0 0 10px rgba(0,0,255,0.3);
    font-weight: 400;
    color: #b3b3e5;
    font-size: 25px;
    margin: 25px 0 0 0;
  }
  .main-content .feature .feature-description-container div p .faq .answer {
    font-size: 20px;
    font-weight: 300;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    color: #ddd;
    line-height: 150%;
  }
  .main-content .feature .feature-description-container div p .faq .answer:before {
    content: "RE: ";
    font-size: 17px;
    color: #888;
text-trans
  }
  footer .row {
    width: 90%;
  }
  footer .row .footer-col {
    height: 90px;
  }
  footer .row .footer-col ul {
    text-align: center;
  }
  footer .row .footer-col ul li {
    display: inline-block;
    margin: 0 20px;
  }
  footer .row .footer-col:after {
    display: none;
  }
  footer .footer-col.col-sm-10 {
    height: 30px;
  }
  footer .footer-col.col-sm-10.rights {
    margin-top: 20px;
  }
  footer .footer-col.col-sm-10.email {
    margin-top: 0;
  }
}
