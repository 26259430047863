@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
footer {
  height: 201px;
  width: 100%;
  border-top: 1px solid #00f;
  background-color: #000;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 1000px) {
  footer {
    height: 251px;
  }
  footer .row .footer-col.rights {
    height: 50px;
  }
}
footer .row {
  color: #b3b3e5;
  margin-top: 150px;
  margin: 0 auto;
  width: 80%;
}
footer .row .footer-col {
  position: relative;
  height: 200px;
}
footer .row .footer-col nav,
footer .row .footer-col p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  margin: 0 auto;
}
footer .row .footer-col ul {
  list-style: none;
  text-align: right;
  margin: 0 20px 0 0;
  padding: 0;
}
footer .row .footer-col ul a {
  text-transform: uppercase;
  color: #b3b3e5;
  transition: 0.3s all;
}
footer .row .footer-col ul a:hover {
  color: #fff;
}
footer .row .footer-col:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 120px;
  background-color: #00f;
  transform: translateY(-50%);
}
footer .row .footer-col.no-after:after {
  display: none;
}
footer .row .footer-col p {
  font-weight: 300;
}
footer .row .footer-col p.copyright {
  font-size: 16px;
  margin: 3px 40px;
}
footer .row .footer-col p.footer-email {
  text-align: left;
  font-size: 30px;
  white-space: pre;
  margin-left: 30px;
}
@media screen and (max-width: 1367px) {
  footer .row .footer-col p.footer-email {
    font-size: 19px;
  }
}
footer .row .footer-col p.footer-email:before {
  content: "";
  background-image: url("../img/mail-icon.png");
  background-repeat: no-repeat;
  height: 47px;
  width: 63px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
@media screen and (max-width: 1367px) {
  footer .row .footer-col p.footer-email:before {
    height: 25px;
    width: 34px;
    background-size: 100%;
  }
}
