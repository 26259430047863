@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
.contributor {
  margin: 30px auto;
  position: relative;
}
@media screen and (max-width: 1367px) {
  .contributor {
    margin: 20px auto;
  }
}
@media screen and (max-width: 1000px) {
  .contributor {
    margin: 30px auto;
    position: relative;
  }
}
.contributor h3 {
  margin: 20px 0 10px 30px;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  text-shadow: 0 0 25px rgba(0,0,255,0.7);
}
@media screen and (max-width: 1367px) {
  .contributor h3 {
    margin: 20px 0 5px 30px;
    font-size: 21px;
  }
}
@media screen and (max-width: 1000px) {
  .contributor h3 {
    margin: 20px 0 10px 30px;
  }
}
.contributor p {
  padding: 0 35px;
  color: #b3b3e5;
}
@media screen and (max-width: 1367px) {
  .contributor p {
    font-size: 19px;
  }
}
.contributor .contributor-description {
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  display: inline-block;
}
