@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
.faq {
  margin: 20px 0;
  font-size: 25px;
  font-weight: 500;
  padding: 30px 30px 30px 80px;
  background-color: rgba(0,0,0,0.5);
  color: #b3b3e5;
}
.faq .question {
  text-shadow: 0 0 10px rgba(0,0,255,0.3);
  font-weight: 400;
  color: #b3b3e5;
  font-size: 25px;
  margin: 25px 0 0 0;
}
.faq .answer {
  font-size: 20px;
  font-weight: 300;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
  color: #ddd;
  line-height: 150%;
}
.faq .answer:before {
  content: "RE: ";
  font-size: 17px;
  color: #888;
  text-transform: uppercase;
}
