@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,latin-ext");
.team-member {
  margin: 30px auto;
  position: relative;
}
@media screen and (max-width: 1367px) {
  .team-member {
    margin: 20px auto;
  }
}
@media screen and (max-width: 1000px) {
  .team-member {
    margin: 0 auto;
    text-align: center;
    position: relative;
  }
}
.team-member img {
  width: 275px;
  height: 275px;
  background-color: #000;
  border-right: 1px solid #00f;
  display: inline-block;
  float: left;
}
@media screen and (max-width: 1367px) {
  .team-member img {
    width: 215px;
    height: 215px;
  }
}
@media screen and (max-width: 1000px) {
  .team-member img {
    width: 200px;
    height: 200px;
    border-right: none;
    text-align: center;
    float: none;
    border-radius: 50%;
    transform: translateY(50%);
    border: 2px solid #00f;
  }
}
.team-member h3 {
  margin: 20px 0 10px 30px;
  font-weight: 500;
  font-size: 32px;
  text-transform: uppercase;
  text-shadow: 0 0 25px rgba(0,0,255,0.7);
}
@media screen and (max-width: 1367px) {
  .team-member h3 {
    margin: 20px 0 10px 30px;
    font-size: 28px;
  }
}
@media screen and (max-width: 1000px) {
  .team-member h3 {
    margin: 110px 0 10px 30px;
    font-size: 26px;
  }
}
.team-member p {
  padding: 0 30px;
  color: #b3b3e5;
}
@media screen and (max-width: 1367px) {
  .team-member p {
    font-size: 19px;
  }
}
@media screen and (max-width: 1000px) {
  .team-member p {
    padding: 0 30px 10px;
  }
}
.team-member .team-description {
  height: 275px;
  width: 925px;
  background-color: rgba(0,0,0,0.5);
  display: inline-block;
}
@media screen and (max-width: 1367px) {
  .team-member .team-description {
    height: 215px;
    width: 745px;
  }
}
@media screen and (max-width: 1000px) {
  .team-member .team-description {
    height: auto;
    width: auto;
    border-top: 1px solid #00f;
  }
}
.team-portrait.alternative {
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s;
}
@media screen and (max-width: 1000px) {
  .team-portrait.alternative {
    display: none;
  }
}
.team-portrait.alternative:hover {
  opacity: 1;
}
.team footer {
  margin-top: 100px;
}
